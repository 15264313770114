<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col class="d-flex justify-content-between flex-column md-6">
        <b-row class="align-items-center mb-2">
          <b-col cols="12" md="2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class=""
              @click="$router.go(-1)"
            >
              <feather-icon icon="ArrowLeftIcon" class="mr-25" />
              <span>List</span>
            </b-button>
          </b-col>
          <b-col cols="12" md="6">
            <h4>FAQ Details:</h4>
          </b-col>
          <b-col cols="12" md="6" />
        </b-row>
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold"> Module </b-col>
              <b-col md="6" class="pb-50">
                {{ userData.module.module_name }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold"> Faq Status </b-col>
              <b-col md="6" class="pb-50">
                {{ userData.status ? "Active" : " Inactive" }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <b-row>
              <b-col md="3" class="pb-50 font-weight-bold"> Question </b-col>
              <b-col md="9" class="pb-50">
                {{ userData.question }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <b-row>
              <b-col md="3" class="pb-50 font-weight-bold"> Answer </b-col>
              <b-col md="9" class="pb-50">
                {{ userData.answer }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <div
              class="border-top pt-1"
              v-if="userData.attachments && userData.attachments.length > 0"
            >
              <b class="text-secondary">Uploaded Attachments</b>

              <div class="d-flex flex-column">
                <div
                  v-for="(file, index) in userData.attachments"
                  :key="index"
                  class="d-flex align-items-center justify-content-between my-1"
                >
                  <img
                    v-if="file.type.includes('image')"
                    :src="file.link"
                    class="img-fluid"
                    width="100px"
                    height="100px"
                    alt="Attachment"
                  />
                  <span
                    v-else
                    class="text-primary"
                    v-b-tooltip.hover.v-default
                    :title="file.name"
                  >
                    {{ file.name | str_limit(40) }}
                  </span>
                  <div>
                    <feather-icon
                      v-b-tooltip.hover.top="'View Attachment'"
                      size="20"
                      class="btn-link cursor-pointer ml-1"
                      icon="EyeIcon"
                      @click.stop.prevent="openWindow(file.link)"
                    />

                    <feather-icon
                      v-if="$can('faq_edit')"
                      v-b-tooltip.hover.top="'Remove Attachment'"
                      size="20"
                      class="btn-link cursor-pointer ml-1 text-danger"
                      icon="TrashIcon"
                      @click.stop.prevent="confirmDeleteRecord(file.uuid)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-for="(data, index) in customFields" v-bind:key="index" md="6">
        <b-row>
          <b-col md="6" class="pb-50 font-weight-bold">
            {{ data.label }}
          </b-col>
          <b-col md="6" class="pb-50">
            {{ userData.get_customfields_data["field_" + data.id] }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton, VBTooltip } from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import Vue from "vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    ToastificationContent,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    customFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userDataLocal: this.$cookies.get("userData"),
    };
  },
  methods: {
    openWindow(link) {
      window.open(link);
    },
    confirmDeleteRecord(uuid) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete file.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeAttachment(uuid);
        }
      });
    },
    removeAttachment(uuid) {
      let data = {};
      data.url = "faqs/media/destroy/" + uuid;
      this.isBusy = true;
      this.$store
        .dispatch("app/delete", data)
        .then((res) => {
          this.isBusy = false;
          this.$router.go(-1);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Attachment Deleted",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: "Attachment Deleted Successfully.",
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Attachment Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Attachment Deleted Successfully.',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });


        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Attachment Deletion failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Attachment Deletion failed.`,
              icon:'BellIcon',
              variant : 'danger',
              hideClose: true,
              
            },
                },
                {
            timeout : 3000,
            position : 'bottom-center',
            toastClassName:"error-info",
            hideProgressBar : true,
                })
        });
    },
  },
};
</script>

<style>
</style>
